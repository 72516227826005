import { InternalAxiosRequestConfig } from 'axios';
import { useUser } from '../../stores/user/useUser';

const onAuthError = (): void => {
  useUser.setState({
    user: {},
  });
};

const AuthInterceptor = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const isAuthRequired = (config.headers as any)?.authHeader !== undefined;

  if (isAuthRequired) {
    try {
      const { accessToken } = useUser.getState().user;
      if (!accessToken) {
        throw new Error('accessToken is undefined');
      }
      const configWithAuth: any = {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      };
      delete configWithAuth.headers['authHeader'];
      return configWithAuth;
    } catch (err) {
      onAuthError();
    }
  }
  return config;
};

export default AuthInterceptor;
