import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.default,
}));

interface RouteLoaderProps {
  width?: number | string;
  height?: number | string;
}

const RouteLoader: React.FC<RouteLoaderProps> = ({ width, height }) => (
  <Container width={width ?? '100%'} height={height ?? '100vh'}>
    <CircularProgress />
  </Container>
);

export default RouteLoader;
