import { useEffect, useState } from 'react';
import { getAppVersion } from '../../designPatterns/adapter/getAppVersion';
import { env } from '../../env';

export const useValidateDesktopClientVersion = () => {
  const [isMinVersion, setIsMinVersion] = useState(true);

  const validate = async () => {
    const currentVersion = getAppVersion() ?? '0.0.0';
    const minVersion = env.REACT_APP_DESKTOP_MIN_REQUIRED_VERSION ?? '0.0.0';

    const splittedCurrentVersion = currentVersion.split('.').map(Number);
    const splittedMinVersion = minVersion.split('.').map(Number);

    let isValid = false;

    for (let i = 0; i < splittedCurrentVersion.length; i++) {
      if (splittedCurrentVersion[i] > splittedMinVersion[i]) {
        isValid = true;
        break;
      } else if (splittedCurrentVersion[i] < splittedMinVersion[i]) {
        break;
      } else if (i === splittedCurrentVersion.length - 1) {
        isValid = true;
      }
    }

    return isValid;
  };

  useEffect(() => {
    if (env.isProd && !window.ReactNativeWebView) {
      validate().then((isValid) => setIsMinVersion(isValid));
    }
  }, []);

  return isMinVersion;
};
