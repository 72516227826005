import { Button, Stack, Typography, styled } from '@mui/material';
import { captureException } from '@sentry/react';
import React, { useEffect, useState } from 'react';
import ErrorDetailsDialog from './components/ErrorDetailsDialog';

const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundColor: theme.palette.grey[100],
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  paddingBottom: 16,
}));

const ContentContainer = styled(Stack)(({ theme }) => ({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

const ShowErrorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.grey[700],
  textTransform: 'none',
  padding: 0,

  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
}));

type ErrorFallbackProps = {
  error: Error;
  componentStack: string;
};

const ErrorFallback: React.FC<ErrorFallbackProps> = ({
  error,
  componentStack,
}) => {
  const [showErrorDetailsDialog, setShowErrorDetailsDialog] = useState(false);

  const handleOpenErrorDetailsDialog = () => {
    setShowErrorDetailsDialog(true);
  };

  const handleCloseErrorDetailsDialog = () => {
    setShowErrorDetailsDialog(false);
  };

  useEffect(() => {
    captureException(new Error('React fatal error'));
  }, []);

  return (
    <>
      <ErrorDetailsDialog
        open={showErrorDetailsDialog}
        error={error}
        componentStack={componentStack}
        onClose={handleCloseErrorDetailsDialog}
      />

      <Container>
        <ContentContainer>
          <Typography
            variant="h5"
            style={{ fontWeight: 700, marginBottom: 16, textAlign: 'center' }}
          >
            Algo deu errado com nossa aplicação!
          </Typography>

          <Typography style={{ textAlign: 'center' }}>
            Clique no botão abaixo para recomeçar.
          </Typography>

          <Typography style={{ textAlign: 'center' }}>
            Caso esse erro persista, entre em contato com a operação.
          </Typography>

          <Button
            disableElevation
            variant="contained"
            style={{ marginTop: 24 }}
            onClick={() => document.location.reload()}
          >
            recarregar
          </Button>
        </ContentContainer>

        <ShowErrorButton
          disableRipple
          color="inherit"
          onClick={handleOpenErrorDetailsDialog}
        >
          Exibir erro
        </ShowErrorButton>
      </Container>
    </>
  );
};

export default ErrorFallback;
