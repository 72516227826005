export const systemTheme = {
  async setTheme(theme: 'dark' | 'light' | 'system'): Promise<boolean> {
    if (window.electron?.shell) {
      return window.electron.theme.setTheme(theme);
    }

    return false;
  },
  isDarkTheme(): Promise<boolean> {
    if (window.electron?.theme) {
      return window.electron.theme.isDarkTheme();
    }
    return new Promise((resolve) => {
      resolve(false);
    });
  },
};
