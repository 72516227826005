/* eslint-disable no-extend-native */

(() => {
  if (!Array.prototype.flat) {
    Array.prototype.flat = function <A, D>(this: A, depth?: D) {
      var flattened: any[] = [];

      function flatten(arr, currentDepth) {
        for (var i = 0; i < arr.length; i++) {
          if (Array.isArray(arr[i]) && currentDepth < (depth ?? 0)) {
            flatten(arr[i], currentDepth + 1);
          } else {
            flattened.push(arr[i]);
          }
        }
      }

      flatten(this, 0);

      return flattened;
    };
  }
})();

export {};
