import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  styled,
  Tooltip,
} from '@mui/material';
import { newTab, Typographies } from '@wls-solucoes/lets-eat-core';
import React from 'react';
import { ipcRenderer } from '../../../designPatterns/adapter/ipcRenderer';
import backgroundIllustration from '../../../public/available-update-illustration.svg';

const DialogPaper = styled(Paper)(() => ({
  width: 835,
  maxWidth: 'unset !important',
  height: 440,
  padding: '0 0 32px 273px',
  margin: '8px !important',
  borderRadius: 10,
  backgroundImage: `url(${backgroundIllustration})`,
  backgroundRepeat: 'no-repeat',
  backgroundPositionY: 'bottom',

  '& .MuiDialogContent-root': {
    padding: '24px 46px 8px 32px',
  },

  '& .MuiDialogActions-root': {
    padding: '8px 46px 32px 32px',
    gap: 48,
  },
}));

const Title = styled(Typographies.heading.h2.bold)(({ theme }) => ({
  width: 'max-content',
  marginBottom: 8,

  '&::before': {
    content: '""',
    display: 'block',
    width: 90,
    height: 3,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    marginLeft: 'auto',
  },

  '& span': {
    color: theme.palette.primary.main,
  },
}));

const Text = styled(Typographies.body.text16.regular)(({ theme }) => ({
  color: theme.palette.text.primary,

  '& + &': {
    marginTop: 4,
  },
}));

interface AvailableUpdateDialogProps {
  open: boolean;
  onClose: () => void;
}

const AvailableUpdateDialog: React.FC<AvailableUpdateDialogProps> = ({
  open,
  onClose,
}) => {
  const handleInstallUpdate = () => {
    ipcRenderer.sendMessage('update:install');
  };

  return (
    <Dialog open={open} PaperComponent={DialogPaper} onClose={onClose}>
      <Stack padding={3}>
        <Tooltip arrow title="Fechar">
          <IconButton style={{ alignSelf: 'end' }} onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>

      <DialogContent>
        <Title variant="h2">
          <span>Atualização</span> Disponível!
        </Title>

        <Text>
          Uma <strong>nova versão do ComprAqui foi Baixada</strong>,
          recomendamos <strong>atualizar o aplicativo</strong> para aproveitar
          as <strong>melhorias e novidades</strong> mais recentes.
        </Text>

        <Text>
          Verifique a possibilidade de{' '}
          <strong>atualizar automaticamente</strong> clicando em{' '}
          <strong>ATUALIZAR</strong> ou <strong>baixe manualmente</strong> a
          nova versão clicando em <strong>BAIXAR ATUALIZAÇÕES</strong>.
        </Text>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button
          variant="outlined"
          onClick={() =>
            newTab('https://download.compraqui.app/download/win32')
          }
        >
          baixar atualizações
        </Button>

        <Button
          disableElevation
          variant="contained"
          onClick={handleInstallUpdate}
        >
          atualizar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvailableUpdateDialog;
