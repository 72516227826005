import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

function render() {
  const container = document.getElementById('root');
  if (container === null)
    throw new Error('Root container missing in index.html');
  const root = createRoot(container);
  root.render(<App />);
}

render();
