import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => {
  return {
    '@global': {
      '*::-webkit-scrollbar': {
        width: '0.4rem',
        height: '0.4rem',
        background: '#d1d3d4',
        '-moz-border-radius': '0.5rem',
      },

      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#3DA865',
      },
    },
  };
});

const CustomScrollBar: React.FC = () => {
  const classes = useStyles();

  return <div className={classes['@global']} />;
};

export default CustomScrollBar;
