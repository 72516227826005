import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useUser } from '../../stores/user/useUser';
import { authService } from '../services/auth';

export const errorInterceptor = (error: any): Promise<any> => {
  const response = error.response as AxiosResponse;

  if (!response) {
    return Promise.reject(error);
  }

  if (response.status === 401) {
    const { user, setTokens, logout } = useUser.getState();

    if (user.refreshToken) {
      authService
        .refresh(user.refreshToken)
        .then(({ accessToken, refreshToken }) => {
          setTokens(accessToken, refreshToken);
        })
        .catch(() => {
          toast.error('Não autorizado');

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ eventName: 'logout' })
            );
          }

          logout();
        });
    } else {
      toast.error('Não autorizado');

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ eventName: 'logout' })
        );
      }

      logout();
    }
  } else if (response?.data?.message !== undefined) {
    toast.error(response.data.message);
  } else if (response !== undefined) {
    toast.error('Erro desconhecido');
  }

  return Promise.reject(error);
};
