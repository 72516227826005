import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { init } from '@sentry/electron/renderer';
import {
  BrowserTracing,
  ErrorBoundary,
  init as reactInit,
} from '@sentry/react';
import { Integration } from '@sentry/types';
import '@szhsin/react-menu/dist/index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ExternalCommunicationProvider } from '@wls-solucoes/lets-eat-core';
import {
  OrderManagerExternals,
  OrderManagerExternalsKey,
} from '@wls-solucoes/lets-eat-externals';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import 'flat-map-polyfill/dist/cjs';
import { ConfirmProvider } from 'material-ui-confirm';
import moment from 'moment';
import 'moment/locale/pt-br';
import { SnackbarProvider } from 'notistack';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { ipcRenderer } from './designPatterns/adapter/ipcRenderer';
import { env } from './env';
import { sentry } from './helpers/adapter/sentry';
import { useValidateDesktopClientVersion } from './helpers/hooks/useValidateDesktopClientVersion';
import './polyfills/flat';
import './polyfills/padStart';
import AppTheme from './shared/components/ApplicationTheme/ApplicationTheme';
import AvailableUpdateDialog from './shared/components/AvailableUpdateDialog';
import ErrorFallback from './shared/components/ErrorFallback';
import RouteLoader from './shared/components/RouteLoader';
import { analytics } from './shared/services/analytics';
import { mixPanel } from './shared/services/mixPanel';
import { useUser } from './stores/user/useUser';

const Router = lazy(() => import('./router'));
const Login = lazy(() => import('./components/Login'));
const RequiredUpdateAlert = lazy(
  () => import('./shared/components/RequiredUpdateAlert')
);

moment().locale('pt-br');
dayjs.locale('pt-br');
dayjs.extend(utc);

sentry().then((isActiveInDev) => {
  if (window.ReactNativeWebView) return;

  if (env.isProd || isActiveInDev) {
    init(
      {
        integrations: [
          new BrowserTracing({
            tracePropagationTargets: ['localhost'],
          }) as Integration,
        ],
        tracesSampleRate: 0.7,
      },
      reactInit as any
    );
  }
});

const RETRY_COUNT = 10;
export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: (failureCount, error: any) => {
        return (
          failureCount < RETRY_COUNT &&
          error.response?.data?.message === undefined
        );
      },
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

export type RoutesName =
  | 'home'
  | 'orders'
  | 'whatsapp'
  | 'web'
  | 'config'
  | 'pdv'
  | 'kds';

const App: React.FC = () => {
  const user = useUser((state) => state.user);
  const isLogged = user.accessToken;
  const isOnMinRequiredVersion = useValidateDesktopClientVersion();

  const [showAvailableUpdateDialog, setShowAvailableUpdateDialog] =
    useState(false);

  const handleOpenAvailableUpdateDialog = () => {
    setShowAvailableUpdateDialog(true);
  };

  const handleCloseAvailableUpdateDialog = () => {
    setShowAvailableUpdateDialog(false);
  };

  useEffect(() => {
    ipcRenderer.on('analytics:setGAInteraction:nativeMenu', (eventLabel) =>
      analytics.setGAInteraction('clicou', `menu nativo ${eventLabel}`)
    );

    ipcRenderer.on('update:downloaded', () => {
      handleOpenAvailableUpdateDialog();
    });

    mixPanel.init();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ExternalCommunicationProvider
        disableFallback={!window.ReactNativeWebView}
        eventKey={OrderManagerExternalsKey}
        fallback={<RouteLoader />}
        onSetVariablesListener={(variables) => {
          const vars = variables as OrderManagerExternals['data'];

          if (window.ReactNativeWebView && !vars.accessToken && !isLogged) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ eventName: 'logout' })
            );
          }
        }}
      >
        <AppTheme>
          <ErrorBoundary
            fallback={({ error, componentStack }) => (
              <ErrorFallback error={error} componentStack={componentStack} />
            )}
          >
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="pt-br"
            >
              <SnackbarProvider
                maxSnack={3}
                hideIconVariant
                classes={{
                  variantInfo: '',
                }}
              >
                <ConfirmProvider
                  defaultOptions={{
                    dialogProps: {
                      maxWidth: 'xs',
                    },
                    cancellationButtonProps: {
                      tabIndex: -1,
                    },
                    confirmationButtonProps: {
                      autoFocus: true,
                    },
                  }}
                >
                  <Suspense fallback={<RouteLoader />}>
                    {isOnMinRequiredVersion ? (
                      <>
                        <AvailableUpdateDialog
                          open={showAvailableUpdateDialog}
                          onClose={handleCloseAvailableUpdateDialog}
                        />

                        {isLogged ? <Router /> : <Login />}
                      </>
                    ) : (
                      <RequiredUpdateAlert />
                    )}
                  </Suspense>
                </ConfirmProvider>
              </SnackbarProvider>
            </LocalizationProvider>
          </ErrorBoundary>
        </AppTheme>
      </ExternalCommunicationProvider>
    </QueryClientProvider>
  );
};

export default App;
