interface ipcRendererReturn {
  invoke<T>(channel: string, args?: any): Promise<T>;
  sendMessage(channel: string, args?: any): void;
  on(
    channel: string,
    func: (...args: unknown[]) => void
  ): (() => void) | undefined;
  once(channel: string, func: (...args: any) => void): void;
}

export const ipcRenderer: ipcRendererReturn = {
  async invoke<T>(channel: string, args?: any): Promise<T> {
    if (window.electron?.ipcRenderer) {
      return window.electron?.ipcRenderer.invoke(channel, args);
    }
    return null as T;
  },
  sendMessage: (channel: string, args?: any) => {
    if (window.electron?.ipcRenderer) {
      window.electron?.ipcRenderer.sendMessage(channel, args);
      return;
    }

    const event = new CustomEvent(channel, {
      detail: args,
      bubbles: true,
    });
    document.dispatchEvent(event);
  },
  on: (channel: string, func: (...args: any[]) => void) => {
    if (window.electron?.ipcRenderer) {
      window.electron?.ipcRenderer.on(channel, func);
    } else {
      document.addEventListener(channel, function (e: any) {
        func(e.detail);
      });
    }

    return () => {};
  },
  once: (channel: string, func: (...args: any) => void) => {
    if (window.electron?.ipcRenderer) {
      window.electron?.ipcRenderer.once(channel, func);
    } else {
      document.addEventListener(
        channel,
        function (e: any) {
          func(e.detail);
        },
        { once: true }
      );
    }
  },
};
