import Api from './api';

const base = '/api/auth';

async function refresh(
  refreshToken: string
): Promise<{ accessToken: string; refreshToken: string }> {
  return Api.post(`${base}/refresh`, undefined, {
    headers: {
      'x-refresh-token': refreshToken,
      'x-api-version': 3,
      authHeader: true,
    },
  }).then((res) => res.data);
}

export const authService = {
  refresh,
};
