import {
  createTheme,
  PaletteOptions,
  StyledEngineProvider,
  ThemeProvider,
  useMediaQuery,
  useTheme as useMuiTheme,
} from '@mui/material';
import { CoreThemeWrapper } from '@wls-solucoes/lets-eat-core';
import { OrdersThemeWrapper } from '@wls-solucoes/lets-eat-orders';
import React, { ReactElement, useEffect } from 'react';
import { systemTheme } from '../../../designPatterns/adapter/theme';
import { useExternalData } from '../../../helpers/utils/external';
import { useTheme } from '../../../stores/theme/useTheme';
import CustomScrollBar from '../CustomScrollBar';

export interface AppThemeProps {
  children?: ReactElement<any, any>;
}

export interface ThemeOptions {
  label: string;
  palette: PaletteOptions;
}

export const themes: Record<string, ThemeOptions> = {
  light: {
    label: 'Modo claro',
    palette: {
      mode: 'light',
      text: {
        primary: '#000000',
        secondary: '#4A4F4C',
      },
      common: {
        black: '#000',
        white: '#fff',
      },
      background: {
        default: '#ffffff',
      },
      grey: {
        100: '#E4E6E5',
        200: '#CACECC',
        300: '#B0B5B2',
        400: '#969C98',
        500: '#7C837F',
        600: '#636965',
        700: '#4A4F4C',
        800: '#313533',
        900: '#191A19',
      },
      primary: {
        main: '#3DA865',
        dark: '#2B7647',
        light: '#ECF6F0',
      },
      secondary: {
        main: '#1F5433',
      },
    },
  },
  dark: {
    label: 'Modo escuro',
    palette: {
      mode: 'dark',
      text: {
        primary: '#ffffff',
        secondary: '#CACECC',
      },
      common: {
        black: '#000',
        white: '#fff',
      },
      background: {
        default: '#121212',
        paper: '#313533',
      },
      grey: {
        100: '#3c403d',
        200: '#636965',
        300: '#ffffff',
        400: '#CACECC',
        500: '#7C837F',
        600: '#969C98',
        700: '#B0B5B2',
        800: '#313533',
        900: '#191A19',
      },
      primary: {
        main: '#3DA865',
        dark: '#2B7647',
        light: '#ECF6F0',
      },
      secondary: {
        main: '#1F5433',
      },
    },
  },
};

function generateSwalCSSLink(isDark: boolean) {
  if (isDark) {
    return '//cdn.jsdelivr.net/npm/@sweetalert2/theme-dark@4/dark.css';
  }
  return '//cdn.jsdelivr.net/npm/@sweetalert2/theme-minimal/minimal.css';
}

const AppTheme: React.FC<AppThemeProps> = ({ children }) => {
  const currentTheme = useExternalData((state) => state.theme);
  const { breakpoints } = useMuiTheme();
  const iNotsm = useMediaQuery(breakpoints.up('sm'));

  const { theme: selectedThemeName, setTheme } = useTheme();
  const appTheme = createTheme({
    typography: {
      fontFamily: ['DM Sans', 'Roboto', 'sans-serif'].join(','),
      body1: {
        fontWeight: 500,
      },
      subtitle1: {
        fontFamily: ['Nunito', 'Roboto', 'sans-serif'].join(','),
      },
    },
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: 'rgba(97, 97, 97, 0.92)',
          },
          tooltipArrow: {
            backgroundColor: 'rgba(97, 97, 97, 0.92)',
          },
          arrow: {
            color: 'rgba(97, 97, 97, 0.92)',
          },
        },
      },
    },

    palette: themes[selectedThemeName ?? 'light'].palette,
  });

  useEffect(() => {
    if (!currentTheme) return;

    setTheme(currentTheme);
  }, [currentTheme]); // eslint-disable-line

  useEffect(() => {
    if (!selectedThemeName && !currentTheme) {
      systemTheme.isDarkTheme().then((isDarkTheme) => {
        setTheme(isDarkTheme ? 'dark' : 'light');
      });
      return;
    }

    if (selectedThemeName === 'light' || selectedThemeName === 'dark') {
      systemTheme.setTheme(selectedThemeName);
    }
  }, [selectedThemeName]); // eslint-disable-line

  return (
    <div>
      <link
        rel="stylesheet"
        href={generateSwalCSSLink(selectedThemeName === 'dark')}
      />
      <StyledEngineProvider injectFirst>
        <CoreThemeWrapper theme={appTheme}>
          <OrdersThemeWrapper theme={appTheme}>
            {iNotsm && <CustomScrollBar />}
            <ThemeProvider theme={appTheme}>{children}</ThemeProvider>
          </OrdersThemeWrapper>
        </CoreThemeWrapper>
      </StyledEngineProvider>
    </div>
  );
};

export default AppTheme;
