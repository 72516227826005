import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface useThemeState {
  theme?: string;
  setTheme: (theme: string) => void;
}

export const useTheme = create(
  persist<useThemeState>(
    (set) => ({
      setTheme(theme) {
        set({ theme });
      },
    }),
    {
      name: 'theme',
    }
  )
);
