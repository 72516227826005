import {
  makeExternalActions,
  makeExternalData,
} from '@wls-solucoes/lets-eat-core';
import { OrderManagerExternals } from '@wls-solucoes/lets-eat-externals';
import { env } from '../../env';

export const useExternalData = makeExternalData<
  Partial<OrderManagerExternals['data']>
>(env.isDev);

export const useExternalActions = makeExternalActions<
  OrderManagerExternals['actions']
>(env.isDev);
