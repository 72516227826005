import mixpanel from 'mixpanel-browser';
import { env } from '../../env';
import { useUser } from '../../stores/user/useUser';

function init() {
  mixpanel.init(env.REACT_APP_MIX_PANEL_TOKEN ?? '', {
    api_host: `https://${env.REACT_APP_MIX_PANEL_PROXY}`,
    debug: env.REACT_APP_MIX_PANEL_DEBUG === 'true',
    track_pageview: true,
    persistence: 'cookie',
  });
}

function identify(userGuid: string) {
  mixpanel.identify(userGuid);
}

function resetIdentification() {
  mixpanel.reset();
}

function track(event: string, properties?: any) {
  const establishmentGuid = useUser.getState().user.establishment?.guid;

  mixpanel.track(event, {
    ...properties,
    establishment_guid: [establishmentGuid],
  });
}

function startSessionRecording() {
  (mixpanel as any).start_session_recording();
}

function stopSessionRecording() {
  (mixpanel as any).stop_session_recording();
}

function startTrackingNavigationTime() {
  if ((window as any).isTrackingNavigationTime) return;

  (window as any).isTrackingNavigationTime = true;

  const eventSchedule = [1, 2, 3, 5, 10, 30, 45, 60];
  let navigationTime = 0;

  const timer = setInterval(() => {
    navigationTime += 1;

    if (eventSchedule.includes(navigationTime)) {
      track(`navigated-${navigationTime}min`);

      if (eventSchedule.indexOf(navigationTime) === eventSchedule.length - 1) {
        clearInterval(timer);
      }
    }
  }, 1000 * 60);
}

function logPageView(id: string) {
  track(`pageview-${id}`);
}

export const mixPanel = {
  init,
  identify,
  resetIdentification,
  track,
  startSessionRecording,
  stopSessionRecording,
  startTrackingNavigationTime,
  logPageView,
};
