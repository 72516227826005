import { logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { RoutesName } from '../../App';
import { getAppVersion } from '../../designPatterns/adapter/getAppVersion';
import { env } from '../../env';
import { analytics as gtag } from '../../firebase/analytics';
import { useExternalData } from '../../helpers/utils/external';
import { usePageStore } from '../../stores/page/usePage';

const pageToUse = (page: string): string => {
  const split = page.split('/');

  const pageNameToUse = split[split.length - 1];
  switch (pageNameToUse) {
    case 'itemDetails':
      return 'itemDetails';
    case 'cart':
      return 'cart';
    case 'ClientOrder':
      return 'ClientOrder';
    default:
      return page;
  }
};

function createCustomDimensions(customDimensions: object): void {
  logEvent(gtag, 'config', {
    custom_map: customDimensions,
  });
}

function logPageView(page: string): void {
  const newPageName = pageToUse(page);

  logEvent(gtag, 'config', {
    page_title: newPageName,
    page_path: newPageName,
  });

  if (!env.isProd) {
    console.log(`Page View logged for: ${newPageName}`);
  }
}

function eventsGA(
  event: string,
  establishmentGuid: string,
  options?: object
): void {
  logEvent(gtag, event, { establishment_guid: establishmentGuid, ...options });

  if (!env.isProd) {
    console.log(`GA event captured: ${event}`);
  }
}

function setGAUserInfo(userId: string): void {
  const { envs } = useExternalData.getState();

  const externalAppShellId = envs?.SHELL_APP_ID;
  const externalAppShellVersion = envs?.SHELL_APP_VERSION;

  const properties = {
    crm_id: userId,
    shell_app_id: externalAppShellId ?? env.REACT_APP_SHELL_ID,
    shell_app_version: externalAppShellVersion ?? getAppVersion(),
  };

  setUserId(gtag, userId);

  setUserProperties(gtag, properties);

  if (!env.isProd) {
    console.log(`GA user id set: ${userId}`);
    console.log('GA user properties set:', properties);
  }
}

type DialogRoutesName = 'status-loja' | 'order-details';

export type GARoutesName = RoutesName | DialogRoutesName | 'login';

function getRoutesProps(routeName: GARoutesName) {
  const RoutesProps: Record<
    GARoutesName,
    { page_template: string; page_name: string }
  > = {
    home: {
      page_name: '/home',
      page_template: 'home',
    },
    login: {
      page_name: '/login',
      page_template: 'login',
    },
    config: {
      page_name: '/config',
      page_template: 'config',
    },
    orders: {
      page_name: '/orders',
      page_template: 'orders',
    },
    web: {
      page_name: '/web',
      page_template: 'web',
    },
    pdv: {
      page_name: '/pdv',
      page_template: 'pdv',
    },
    kds: {
      page_name: '/kds',
      page_template: 'kds',
    },
    whatsapp: {
      page_name: '/whats-app',
      page_template: 'whats-app',
    },
    'status-loja': {
      page_name: '/config/status-loja',
      page_template: 'status-loja',
    },
    'order-details': {
      page_name: '/order/order-details',
      page_template: 'order-details',
    },
  };
  return RoutesProps[routeName];
}

function setGAPage(pageName: GARoutesName, lastPage?: GARoutesName) {
  const currentRoute = getRoutesProps(pageName);
  logEvent(gtag, 'CUSTOM_SCREEN_VIEW', {
    page_name: currentRoute.page_name,
    page_template: currentRoute.page_template,
    last_page: lastPage ? getRoutesProps(lastPage).page_template : '',
  });

  if (!env.isProd) {
    console.log(`GA event page: ${currentRoute.page_name}`, {
      page_name: currentRoute.page_name,
      page_template: currentRoute.page_template,
      last_page: lastPage ? getRoutesProps(lastPage).page_template : '',
    });
  }
}

function setGAInteraction(
  action: 'clicou' | 'alterou',
  eventLabel: string,
  page?: GARoutesName,
  eventCategory?: string
) {
  const pageName = page ?? usePageStore.getState().page;
  const currentRoute = getRoutesProps(pageName);
  logEvent(gtag, 'interaction', {
    eventCategory: eventCategory ?? currentRoute.page_template,
    eventAction: action,
    eventLabel: eventLabel,
  });

  if (!env.isProd) {
    console.log(`GA event interaction`, {
      eventCategory: eventCategory ?? currentRoute.page_template,
      eventAction: action,
      eventLabel: eventLabel,
    });
  }
}

function setGASearch(
  action: 'clicou' | 'search',
  searchTerm: string,
  contentType: string,
  page?: GARoutesName
) {
  const pageName = page ?? usePageStore.getState().page;
  const currentRoute = getRoutesProps(pageName);
  logEvent(gtag, 'search', {
    eventCategory: currentRoute.page_template,
    eventAction: action,
    content_type: contentType,
    search_term: searchTerm,
  });

  if (!env.isProd) {
    console.log(`GA event search`, {
      eventCategory: currentRoute.page_template,
      eventAction: action,
      content_type: contentType,
      search_term: searchTerm,
    });
  }
}

export const analytics = {
  createCustomDimensions,
  logPageView,
  eventsGA,
  setGAUserInfo,
  setGAPage,
  setGAInteraction,
  setGASearch,
};
