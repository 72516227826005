import { create } from 'zustand';
import { RoutesName } from '../../App';
import { analytics, GARoutesName } from '../../shared/services/analytics';

interface usePageState {
  page: RoutesName;
  lastPage?: GARoutesName;
  props?: any;
  setPage: (page: RoutesName, props?: any) => void;
  setLastPage: (page: GARoutesName) => void;
}

export const usePageStore = create<usePageState>((set, get) => ({
  page: 'orders',
  setLastPage: (lastPage: GARoutesName) => {
    set({ lastPage });
  },
  setPage: (newPage: RoutesName, props?: any) => {
    const lastPage = get().page;
    set({ page: newPage, props, lastPage });
    analytics.setGAPage(newPage, lastPage ?? '');
  },
}));
