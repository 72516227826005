import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  Grow,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

const DialogPaper = styled(Paper)(({ theme }) => ({
  maxWidth: '850px !important',
  margin: '8px !important',
  borderRadius: '8px !important',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? theme.palette.grey[900]
      : theme.palette.background.paper,
  border:
    theme.palette.mode === 'dark'
      ? `1px solid ${theme.palette.grey[800]}`
      : undefined,
  backgroundImage: 'none !important',

  '& .MuiDialogContent-root': {
    padding: '0 16px 16px',
  },
}));

const DialogHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'end',
  alignItems: 'center',
  padding: '16px 16px 0',
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

interface ErrorDetailsDialogProps {
  open: boolean;
  error: Error;
  componentStack: string;
  onClose: () => void;
}

const ErrorDetailsDialog: React.FC<ErrorDetailsDialogProps> = ({
  open,
  error,
  componentStack,
  onClose,
}) => (
  <Dialog
    open={open}
    TransitionComponent={Transition}
    PaperComponent={DialogPaper}
    onClose={onClose}
  >
    <DialogHeader>
      <Tooltip arrow title="Fechar">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Tooltip>
    </DialogHeader>

    <DialogContent>
      <pre style={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>
        <code>
          <p style={{ fontSize: '1.125rem', fontWeight: 700 }}>
            {error.toString()}
          </p>

          <p>{componentStack}</p>
        </code>
      </pre>
    </DialogContent>
  </Dialog>
);

export default ErrorDetailsDialog;
