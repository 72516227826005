interface OsInfo {
  name: string;
  version: string;
}

export const getOSInfo = (): OsInfo | undefined => {
  if (!window.electron?.getOSInfo) return undefined;

  return window.electron.getOSInfo();
};
