import { UserEstablishment } from '@wls-solucoes/lets-eat-core';
import {
  Establishment,
  PermissionsGroup,
  User,
} from '@wls-solucoes/lets-eat-types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { userPermissionService } from '../../shared/services/api';
import { establishmentService } from '../../shared/services/establishment';
import { mixPanel } from '../../shared/services/mixPanel';

interface UserInfo {
  establishment: Establishment;
  accessToken: string;
  refreshToken: string;
  me: User & {
    establishments?: UserEstablishment | Array<UserEstablishment>;
  };
}

interface useUserState {
  user: Partial<UserInfo>;
  permissions?: PermissionsGroup[];
  setUser: (user: UserInfo) => void;
  setTokens: (accessToken: string, refreshToken: string) => void;
  fetchEstablishment: () => Promise<Establishment>;
  fetchUserPermissions: () => Promise<void>;
  logout: () => void;
}

export const useUser = create(
  persist<useUserState>(
    (set, get) => ({
      user: {},
      setUser: (user: UserInfo) => set({ user }),
      setTokens: (accessToken: string, refreshToken: string) => {
        const { user } = get();

        set({ user: { ...user, accessToken, refreshToken } });
      },
      fetchEstablishment: async () => {
        const establishment =
          await establishmentService.getEstablishmentByToken();
        const user = get().user;
        set({ user: { ...user, establishment } });
        return establishment;
      },
      logout: () => {
        set({ user: {} });
        mixPanel.resetIdentification();
        mixPanel.stopSessionRecording();
        window.Beamer?.destroy();
      },
      fetchUserPermissions: async () => {
        const permissions = await userPermissionService.getAll();

        console.log(permissions);

        set({ permissions });
      },
    }),
    {
      name: 'userState',
    }
  )
);
