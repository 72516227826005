const {
  REACT_APP_BEAMER_PRODUCT_ID,
  REACT_APP_BEAMER_UPDATE_TIME,
  REACT_APP_MIX_PANEL_TOKEN,
  REACT_APP_MIX_PANEL_PROXY,
  REACT_APP_MIX_PANEL_DEBUG,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_LOGO_URL,
  REACT_APP_LOGO_URL_WHITE,
  REACT_APP_ICON_URL,
  REACT_APP_ICON_URL_WHITE,
  REACT_APP_ID,
  REACT_APP_SHELL_ID,
  REACT_APP_VERSION,
  REACT_APP_UPDATE_SERVER_URL,
  REACT_APP_SUPPORT_PHONE_NUMBER,
  REACT_APP_GOOGLE_MAPS,
  REACT_APP_DESKTOP_MIN_REQUIRED_VERSION,
  REACT_APP_WIDGET_ID,
  REACT_APP_PDV_URL,
  REACT_APP_KDS_URL,
  NODE_ENV,
} = process.env;

const isDev = NODE_ENV !== 'production';
const isProd = NODE_ENV === 'production';

export const env = {
  REACT_APP_BEAMER_PRODUCT_ID,
  REACT_APP_BEAMER_UPDATE_TIME: Number(REACT_APP_BEAMER_UPDATE_TIME ?? 30),
  REACT_APP_MIX_PANEL_TOKEN,
  REACT_APP_MIX_PANEL_PROXY,
  REACT_APP_MIX_PANEL_DEBUG,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_LOGO_URL,
  REACT_APP_LOGO_URL_WHITE,
  REACT_APP_ICON_URL,
  REACT_APP_ICON_URL_WHITE,
  REACT_APP_ID,
  REACT_APP_SHELL_ID,
  REACT_APP_VERSION,
  REACT_APP_UPDATE_SERVER_URL,
  REACT_APP_SUPPORT_PHONE_NUMBER,
  REACT_APP_GOOGLE_MAPS,
  REACT_APP_DESKTOP_MIN_REQUIRED_VERSION,
  REACT_APP_WIDGET_ID,
  REACT_APP_PDV_URL,
  REACT_APP_KDS_URL,
  NODE_ENV,
  isDev,
  isProd,
};
