import { InternalAxiosRequestConfig } from 'axios';
import { getAppVersion } from '../../designPatterns/adapter/getAppVersion';
import { getOSInfo } from '../../designPatterns/adapter/getOSInfo';
import { env } from '../../env';
import { useExternalData } from '../../helpers/utils/external';

const addAppInfoToHeadersInterceptor = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  const appVersion = getAppVersion() ?? '';
  const osInfo = getOSInfo();
  const {
    osName: externalOsName,
    osVersion: externalOsVersion,
    envs,
  } = useExternalData.getState();
  const externalAppShellId = envs?.SHELL_APP_ID;
  const externalAppShellVersion = envs?.SHELL_APP_VERSION;

  config.headers = {
    ...config.headers,
    'x-app-id': process.env.REACT_APP_ID,
    'x-os-name': externalOsName ?? osInfo?.name,
    'x-os-version': externalOsVersion ?? osInfo?.version,
    'x-shell-app-id': externalAppShellId ?? env.REACT_APP_SHELL_ID,
    'x-shell-app-version': externalAppShellVersion ?? appVersion,
  } as any;

  return config;
};

export default addAppInfoToHeadersInterceptor;
