import {
  AverageTimeSettings,
  Establishment,
  ManualClosePayload,
  ManualOpenPayload,
} from '@wls-solucoes/lets-eat-types';
import { AxiosResponse } from 'axios';
import Api from './api';

const base = '/api/establishment';
const base2 = '/api/Delivery';

async function getEstablishmentByToken(token?: string): Promise<Establishment> {
  return Api.get(`${base}/EstablishmentDetailsByGuid`, {
    headers: token
      ? { Authorization: `Bearer ${token}` }
      : { authHeader: true },
  }).then((r) => r.data);
}
async function addManualClose(
  payload: ManualClosePayload,
  accessToken: string
): Promise<any> {
  return Api.post('/api/Establishment/manualClose', payload, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      successMessage: 'Loja fechada manualmente',
    },
  });
}

async function addManualOpen(
  payload: ManualOpenPayload,
  accessToken: string
): Promise<any> {
  return Api.post('/api/Establishment/manualOpen', payload, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
}

function updateAverageTime(
  averageTimeSettings: AverageTimeSettings,
  accessToken: string
): Promise<any> {
  return Api.put(`${base2}/averageTime`, averageTimeSettings, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

function removeManualOpen(): Promise<AxiosResponse> {
  return Api.delete('/api/Establishment/manualOpen', {
    headers: { authHeader: true },
  });
}

function removeManualClose(): Promise<AxiosResponse> {
  return Api.delete('/api/Establishment/manualClose', {
    headers: { authHeader: true, successMessage: 'Loja reaberta' },
  });
}

export const establishmentService = {
  getEstablishmentByToken,
  addManualClose,
  addManualOpen,
  updateAverageTime,
  removeManualOpen,
  removeManualClose,
};
